<template>
  <div>
    <!-- <HelpButton /> -->

    <v-select
      :items="select"
      :label="account_name"
      item-value="id"
      item-text="e1_customer_number_c"
      v-model="selectedaccount"
    >
    </v-select>

    <!-- <v-btn id="accountBtn"
        color="#c2c2c2" class="mr-4 acc_select" dark @click="dialog2 = true" >
            {{ account_name }}
        </v-btn>
        <v-dialog
          v-model="dialog2"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              
            </v-card-title>
            <v-card-text>
              <v-select
                :items="select"
                label="Select Customer Number"
                item-value="id"
                item-text="e1_customer_number_c"
                v-model="selectedaccount"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="dialog2 = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="selectAccount"
              >
                Select
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
  </div>
</template>
<script>
// import HelpButton from "../components/HelpButton.vue";

export default {
  // components: {
  //   HelpButton,
  // },
  props: ["displayedtext", "select"],
  data() {
    return {
      dialog2: false,
      notifications: false,
      sound: true,
      widgets: false,
      items: [
        {
          title: "Customer #",
        },
      ],
      selectedaccount: null,
      // select: [],
      account_name: "Customer #",
    };
  },
  methods: {
    selectAccount() {
      console.log("Function triggered");
      console.log(this.selectedaccount);
      let accounts_list = JSON.parse(localStorage.getItem("AccountsList"));
      console.log(accounts_list);
      const selected_account = this.selectedaccount;
      let account_details = accounts_list.find((item) => {
        return item.id == selected_account;
      });
      console.log(account_details);
      localStorage.setItem(
        "SelectedAccount",
        JSON.stringify({
          id: this.selectedaccount,
          name: account_details.name,
          e1_customer_number_c: account_details.e1_customer_number_c,
        })
      );
      location.reload();
    },
  },
  mounted() {
    console.log("AccountsSelector ");
    if (localStorage.getItem("SelectedAccount") !== null) {
      let account_data = JSON.parse(localStorage.getItem("SelectedAccount"));
      this.account_name = "Customer # - " + account_data.e1_customer_number_c;
    }

    this.items = this.select;
    console.log("User has only one account  " + this.select.length);

    this.select = JSON.parse(localStorage.getItem("AccountsList"));
    if (
      this.select.length === 1 &&
      localStorage.getItem("SelectedAccount") === null
    ) {
      console.log("User has only one account");
      this.selectedaccount = this.select[0].id;
      this.selectAccount();
    }
  },
  watch: {
    selectedaccount() {
      this.selectAccount();
    },
    select() {
      if (
        this.select.length === 1 &&
        localStorage.getItem("SelectedAccount") === null
      ) {
        console.log("User has only one account");
        this.selectedaccount = this.select[0].id;
        this.selectAccount();
      }
      this.selectAccount();
    },
  },
};
</script>
<style scoped>
.acc_select {
  margin-right: 20px;
}
.v-text-field {
  padding-top: unset !important;
  margin-right: 15px;
  width: 300px;
}
.v-menu__content {
  margin-top: 20px !important;
}
.v-menu__content theme--light .menuable__content__active {
  margin-top: 20px !important;
}
</style>
