<template>
  <div id="menu-container" class="">
    <nav class="navbar navbar-expand navbar-dark">
      <div class="headerinfo">
        <div class="headerleft">
          <div class="logocontainer">
            <router-link to="/">
              <v-img
                v-if="this.unit == 'Farm' || !this.accountselected"
                src="../assets/images/farmers_logo.png"
                max-height=""
                max-width="100px"
              ></v-img>
              <v-img
                v-if="this.unit == 'Energy'"
                src="../assets/images/FNC-Energy-Management.jpg"
                max-height=""
                max-width="100px"
              ></v-img>
            </router-link>
          </div>
          <div class="navbar-nav mr-auto">
            <router-link :to="isHome ? '/profile' : '/'">
              <v-btn class="nav-item">
                <span v-if="isHome" to="/tutorials" class="nav-link"
                  >Hello, {{ username }}
                  <v-icon icon="mdi-account-box-outline"></v-icon
                ></span>
                <span v-if="!isHome" to="/tutorials" class="nav-link"
                  >GO TO MAIN PAGE<v-icon
                    icon="mdi-account-box-outline"
                  ></v-icon
                ></span>
              </v-btn>
            </router-link>
            &nbsp;&nbsp;<HelpButton />
          </div>
          <div class="navbar-nav mr-auto"></div>
        </div>
        <div>
          <div class="navbar-nav mr-auto" v-if="isHome">
            <v-form
              ref="form"
              class="d-flex justify-space-around flex-wrap flex-column mb-2"
            >
              <div id="header_options">
                <div>
                  <AccountSelector
                    :displayedtext="this.account_name"
                    :select="this.accounts"
                  ></AccountSelector>
                </div>
                <div>
                  <v-btn
                    color="#c2c2c2"
                    class="mr-4"
                    id="logout"
                    :loading="loadstate"
                    v-on:click="logout()"
                    >Logout
                  </v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import http from "../http-common";
import AccountSelector from "./AccountSelector";
import HelpButton from "./HelpButton";

import Cookies from "js-cookie";
export default {
  components: {
    AccountSelector,
    HelpButton,
  },
  props: [
    "username",
    "account_name",
    "unit",
    "accounts",
    "accountselected",
    "isHome",
  ],
  watch: {
    unit: function (val) {
      if (val) {
        // console.log(val);
      }
    },
  },
  data: () => ({
    loadstate: false,
    userIsLoggedIn: localStorage.getItem("userIsLoggedIn") === "true",
    inactivityTimer: null,
    logoutTimeout: 20 * 60 * 1000, // 20 minutes in milliseconds
  }),
  methods: {
    logout(autologout = false) {
      // console.log("logout clicked")
      this.loadstate = true;

      this.userIsLoggedIn = false;
      localStorage.setItem("userIsLoggedIn", "false");
      localStorage.setItem("logoutEvent", "true");

      http
        .post("/logout", {}, { withCredentials: true })
        .then((response) => {
          console.log(response);

          // var cookies = document.cookie.split(";");

          // for (var i = 0; i < cookies.length; i++) {
          //     var cookie = cookies[i];
          //     var eqPos = cookie.indexOf("=");
          //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          // }
          if (autologout) {
            // this.$router.push('/login?session=expired');
            window.location.href = "/login?session=expired";
          } else {
            window.location.href = "/login";
            // this.$router.push('/login');
          }
        })
        .catch((error_data) => {
          // console.log(error_data.response.data);
          this.loadstate = false;
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }

          let error = error_data.response;

          this.error_message = error.data.error_message;
        })
        .finally(() => {
          this.clearSiteData();
          Cookies.remove("connect.sid");
        });
    },
    resetInactivityTimer() {
      // Clear the existing timer
      clearTimeout(this.inactivityTimer);

      // Use localStorage to store the last activity timestamp
      localStorage.setItem("lastActivityTimestamp", Date.now());

      // Start a new timer
      this.inactivityTimer = setTimeout(
        this.checkInactivity,
        this.logoutTimeout
      );
    },
    checkInactivity() {
      const lastActivityTimestamp = localStorage.getItem(
        "lastActivityTimestamp"
      );
      const currentTime = Date.now();

      if (currentTime - lastActivityTimestamp >= this.logoutTimeout) {
        // Perform your logout action here
        console.log("Auto-logout triggered");

        // If you want to trigger a logout event in localStorage, you can do it here
        localStorage.setItem("logoutEvent", "true");

        // Navigate to the login page
        // this.$router.push('/login?session=expired');
        this.logout(true);
        // window.location.href = '/login?session=expired';
      }
    },
    clearSiteData() {
      localStorage.clear();
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
  },
  created() {
    // Watch for changes in local storage
    // const logoutEvent = localStorage.getItem('logoutEvent');
    // if(logoutEvent === 'true'){
    //     this.logout(true);
    // }
    window.addEventListener("storage", (event) => {
      console.log(event.key, event.newValue);
      if (event.key === "logoutEvent" && event.newValue === "true") {
        try {
          this.logout(true);
        } catch (error) {
          console.log(error);
          window.location.href = "/login?session=expired";
          // this.$router.push('/login?session=expired');
        }
      }
      if (event.key === "loginEvent") {
        // If a login event is detected in local storage, refresh the page
        window.location.reload();
      }
    });
    // Check if there's a previous activity timestamp in localStorage
    const lastActivityTimestamp = localStorage.getItem("lastActivityTimestamp");
    if (lastActivityTimestamp) {
      console.log("Checking last activity timestamp");
      // Calculate the time remaining in the current timeout
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastActivityTimestamp;
      const timeRemaining = Math.max(this.logoutTimeout - timeElapsed, 0);
      console.log("Timeremaining", timeRemaining);
      // Start the timer with the remaining time
      this.inactivityTimer = setTimeout(this.checkInactivity, timeRemaining);
    } else {
      // If there's no previous activity, set a default timestamp and start the timer
      localStorage.setItem("lastActivityTimestamp", Date.now());
      this.resetInactivityTimer();
    }

    // Attach event listeners to reset the timer on user activity
    window.addEventListener("mousemove", this.resetInactivityTimer);
    window.addEventListener("keydown", this.resetInactivityTimer);
  },
  beforeDestroy() {
    // Clean up event listeners when the component is destroyed
    window.removeEventListener("mousemove", this.resetInactivityTimer);
    window.removeEventListener("keydown", this.resetInactivityTimer);

    // Clear the inactivity timer
    clearTimeout(this.inactivityTimer);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

#menu-container {
  min-height: 99px;
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  box-shadow: 2px 2px 2px black;
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#menu-container nav {
  width: 80%;
}

#menu-container >>> span {
  color: gray;
}

.logocontainer {
  margin-right: 40px;
}

.headerinfo {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.headerleft {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
}

#menu-container >>> .v-btn__content {
  color: black;
}

#header_options {
  display: flex;
  flex-direction: row;
  width: 50%;
}

/* #logout:hover{
  cursor: pointer;
} */
</style>
